<template>
  <div class="investmentStatistics">
    <h2>{{ $t("headerText.investmentStatistics") }}</h2>
    <div class="commonBox topBox">
      <div class="commonTitle">
        <span>{{ $t("userText.dataStatistics") }}</span>
      </div>
      <div class="commonContent">
        <div class="contentLine">
          <!-- 准确率 -->
          <div class="contentItem">
            <span>{{ topData.correct }}</span>
            <span>{{ $t("userText.accuracy") }}</span>
          </div>
          <!-- 当前持仓 -->
          <div class="contentItem">
            <span>{{ topData.position_count }}</span>
            <span>{{ $t("userText.currentPosition") }}</span>
          </div>
          <!-- 总订单 -->
          <div class="contentItem">
            <span>{{ topData.count }}</span>
            <span>{{ $t("userText.totalOrder") }}</span>
          </div>
          <!-- 盈利订单 -->
          <div class="contentItem">
            <span>{{ topData.profitCount }}</span>
            <span>{{ $t("userText.profitOrder") }}</span>
          </div>
          <!-- 亏损订单 -->
          <div class="contentItem">
            <span>{{ topData.lossCount }}</span>
            <span>{{ $t("userText.lossOrder") }}</span>
          </div>
        </div>
        <div class="contentLine">
          <!-- 成功做多订单 -->
          <div class="contentItem">
            <span>{{ topData.riseCount }}</span>
            <span>{{ $t("userText.successOrder") }}</span>
          </div>
          <!-- 成功做空订单 -->
          <div class="contentItem">
            <span>{{ topData.fallCount }}</span>
            <span>{{ $t("userText.successShortOrder") }}</span>
          </div>
          <!-- 昨日交易状况 -->
          <div class="contentItem">
            <span>{{ topData.yesterday }}</span>
            <span>{{ $t("userText.yesterdayTransaction") }}</span>
          </div>
          <!-- 收益 -->
          <div class="contentItem">
            <span>{{ topData.profit }}</span>
            <span>{{ $t("userText.profitText") }}</span>
          </div>
          <!-- 历史跟随人数 -->
          <div class="contentItem">
            <span>{{ topData.followCount }}</span>
            <span>{{ $t("userText.historyFollow") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomBoxItem commonBox">
      <div class="commonTitle">
        <span>{{ $t("userText.transactionVarieties") }}</span>
      </div>
      <div class="commonContent">
        <!-- 虚拟币秒合约 -->
        <p>{{ $t("userText.fictitiousContractName") }}</p>
        <div class="contentLine">
          <!-- 订单占比 -->
          <div class="contentItem">
            <span>{{ micro.profit }}</span>
            <span>{{ $t("userText.orderRatio") }}</span>
          </div>
          <!-- 买入单数 -->
          <div class="contentItem">
            <span>{{ micro.buy }}</span>
            <span>{{ $t("userText.buyNumber") }}</span>
          </div>
          <!-- 卖出单数 -->
          <div class="contentItem">
            <span>{{ micro.sell }}</span>
            <span>{{ $t("userText.sellNumber") }}</span>
          </div>
        </div>
        <!-- '外汇/贵金属秒合约 -->
        <p>{{ $t("userText.metalsSecondContract") }}</p>
        <div class="contentLine">
          <!-- 订单占比 -->
          <div class="contentItem">
            <span>{{ whmicro.profit }}</span>
            <span>{{ $t("userText.orderRatio") }}</span>
          </div>
          <!-- 买入单数 -->
          <div class="contentItem">
            <span>{{ whmicro.buy }}</span>
            <span>{{ $t("userText.buyNumber") }}</span>
          </div>
          <!-- 卖出单数 -->
          <div class="contentItem">
            <span>{{ whmicro.sell }}</span>
            <span>{{ $t("userText.sellNumber") }}</span>
          </div>
        </div>
        <!-- 虚拟币合约 -->
        <p>{{ $t("userText.virtualCoinContract") }}</p>
        <div class="contentLine">
          <!-- 订单占比 -->
          <div class="contentItem">
            <span>{{ lever.profit }}</span>
            <span>{{ $t("userText.orderRatio") }}</span>
          </div>
          <!-- 买入单数 -->
          <div class="contentItem">
            <span>{{ lever.buy }}</span>
            <span>{{ $t("userText.buyNumber") }}</span>
          </div>
          <!-- 卖出单数 -->
          <div class="contentItem">
            <span>{{ lever.sell }}</span>
            <span>{{ $t("userText.sellNumber") }}</span>
          </div>
        </div>
        <!-- 外汇/贵金属合约 -->
        <p>{{ $t("userText.preciousMetalContracts") }}</p>
        <div class="contentLine">
          <!-- 订单占比 -->
          <div class="contentItem">
            <span>{{ whlever.profit }}</span>
            <span>{{ $t("userText.orderRatio") }}</span>
          </div>
          <!-- 买入单数 -->
          <div class="contentItem">
            <span>{{ whlever.buy }}</span>
            <span>{{ $t("userText.buyNumber") }}</span>
          </div>
          <!-- 卖出单数 -->
          <div class="contentItem">
            <span>{{ whlever.sell }}</span>
            <span>{{ $t("userText.sellNumber") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, getCurrentInstance, watch } from "vue";
import { getdatastatistics } from "@/api/user";
import { useLang } from "@/hooks/useLang";
const { lang } = useLang();
const vm = getCurrentInstance();
const topData = ref({});
const historyData = ref({});
const micro = computed(() => {
  return JSON.stringify(historyData.value) == "{}"
    ? {}
    : historyData.value.micro;
});
const whmicro = computed(() => {
  return JSON.stringify(historyData.value) == "{}"
    ? {}
    : historyData.value.whmicro;
});
const lever = computed(() => {
  return JSON.stringify(historyData.value) == "{}"
    ? {}
    : historyData.value.lever;
});
const whlever = computed(() => {
  return JSON.stringify(historyData.value) == "{}"
    ? {}
    : historyData.value.whlever;
});
// 获取数据
onMounted(async () => {
  const { message } = await getdatastatistics();
  topData.value = message.top;
  historyData.value = message.history;
});
</script>

<style lang="scss" scoped>
.investmentStatistics {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin: 30px;
  }
  .commonBox {
    width: 100%;
    background-color: $section-color;
    box-shadow: $box-shadow;
    .commonTitle {
      height: 57px;
      line-height: 57px;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      border-bottom: 1px solid $market-list-hover;
    }
    .commonContent {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      p {
        padding-left: 20px;
        padding-top: 10px;
        margin: 0;
        font-weight: bold;
        border-top: 1px solid $market-list-hover;
      }
      .contentLine {
        display: flex;
        .contentItem {
          display: flex;
          flex-direction: column;
          padding: 20px 0;
          box-sizing: border-box;
          flex: 1;
          width: 10px;
          align-items: center;
          span:nth-child(1) {
            color: #5670f6;
            font-size: 24px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            width: 100%;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .topBox {
    margin-bottom: 50px;
  }
}
</style>
